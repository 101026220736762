
/*
Position the loading-screen-wrapper element at 0 distance from all four sides of the screen
Effectively centering it
*/

.loading-screen-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color:red;
}


.loading-circle-wrapper {
  position: absolute;

  /* Size of Circle Here*/
  width: 20%;
  height: 20%;

  top: 50%; 
  left: 50%;
  transform: translate(-50%, -100%); 
}


.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.background-image2 {
    z-index: 0;
    position: absolute;
}

/*
 Animation taken from https://codepen.io/aleksander351/pen/KzgKPo
*/

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
            transform-origin: center center;
    opacity: 75%;
  }
  
  .loader-path {
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -124;
    }
  }
  @-webkit-keyframes color {
    0% {
      stroke: #39FF14;
    }
    40% {
      stroke: #39FF14;
    }
    66% {
      stroke: #39FF14;
    }
    80%, 90% {
      stroke: #39FF14;
    }
  }
  @keyframes color {
    0% {
      stroke: #39FF14;
    }
    40% {
      stroke: #39FF14;
    }
    66% {
      stroke: #39FF14;
    }
    80%, 90% {
      stroke: #39FF14;
    }
  }
  