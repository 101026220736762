body {
  margin: 0;
  font-family: -apple-system, blinkmacsystemfont, 'segoe ui', 'roboto', 'oxygen',
    'ubuntu', 'cantarell', 'fira sans', 'droid sans', 'helvetica neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #1b1b20 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-wrapper{
    background-color:black;
}
